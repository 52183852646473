<template>
	<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" style="background-color: #FFF; border-top-right-radius: 10px">
        <router-link to="/">
            <div class="navbar-header pointer pb-0">
                <img src="/assets/img/logo-full.png" alt="" class="img-fluid" id="show-only-expanded">
                <img src="/assets/img/logo.png" alt="" class="img-fluid" id="show-only-collapsed">
                <section v-if="logged_user.role == 'dsi'">
                    <p class="mb-0 text-primary font-600 d-none d-lg-block" style="margin-top: -30px; position: absolute; left: 112px">DSI</p>
                    <p class="mb-0 text-primary font-600 d-lg-none"  style="margin-top: -30px; position: absolute; left: 105px">DSI</p>
                </section>
                <section v-else-if="logged_user.role == 'company'">
                    <p class="mb-0 text-primary font-600 d-none d-lg-block" style="margin-top: -30px; position: absolute; left: 112px">Perusahaan</p>
                    <p class="mb-0 text-primary font-600 d-lg-none"  style="margin-top: -30px; position: absolute; left: 105px">Perusahaan</p>
                </section>
                <section v-else-if="logged_user.role == 'pm'">
                    <p class="mb-0 text-primary font-600 d-none d-lg-block" style="margin-top: -30px; position: absolute; left: 112px">Project Manager</p>
                    <p class="mb-0 text-primary font-600 d-lg-none"  style="margin-top: -30px; position: absolute; left: 105px">Project Manager</p>
                </section>
                <hr class="mb-0">
            </div>
        </router-link>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="margin-bottom: 50px;">
                <div v-for="(data, idx) in menus" :key="idx">
                    <li class="nav-item m-0" :class="isActive(data.active) + (data.has_sub ? ' has-sub ' : '')" style="padding-left: 1rem; padding-right: 1rem; border-left: 0px; margin-bottom: 0px !important;" v-if="isAllowed(data.roles)">
                        <router-link :to="data.url">
                            <i class="material-icons f-25">{{data.icon}}</i>
                            <span class="menu-title align-self-center font-400 f-16">{{data.name}}</span>
                            <span class="badge badge badge-danger badge-pill float-right" v-if="data.counter > 0">{{data.counter}}</span>
                        </router-link>
                        <ul class="menu-content">
                            <li v-for="(dt, i) in data.sub" :key="i">
                                <router-link :to="dt.url">
                                    <i class="ml-2 fa fa-circle"></i>
                                    <span class="menu-item">{{dt.name}}</span>
                                </router-link>
                            </li>
                        </ul>
                        <hr class="m-0">
                    </li>
                </div>
                <li class="nav-item pl-1">
                    <a @click="logout()">
                        <i class="material-icons f-25" style="margin-right: 1.38rem;">logout</i>
                        <span class="menu-title align-self-center f-16">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'Menu',
    data() {
        return {
            menus: [
                {
                    name: 'View Report',
                    url: '/',
                    icon: 'poll',
                    active: [
                        'Home',
                        'ProjectIndex',
                        'ProjectDetailIndex'
                    ],
                    roles: ['dsi']
                },
                {
                    name: 'View Report',
                    url: '/company/view-report',
                    icon: 'poll',
                    active: [
                        'HomeCompany',
                        'ProjectDetailIndex'
                    ],
                    roles: ['company']
                },
                {
                    name: 'Approval Perusahaan',
                    url: '/approval_perusahaan',
                    icon: 'people',
                    active: ['ApprovalPerusahaanIndex'],
                    roles: ['dsi']
                },
                {
                    name: 'Approval PM',
                    url: '/approval_pm',
                    icon: 'people',
                    active: ['ApprovalPMIndex'],
                    roles: ['company']
                },
                // {
                //     name: 'Approval Plan',
                //     url: '/approval_plan',
                //     icon: 'feed',
                //     active: ['ApprovalPlanIndex'],
                //     roles: ['company']
                // },
                {
                    name: 'Project',
                    url: '/project',
                    icon: 'feed',
                    active: ['ProjectPMIndex', 'ProjectDetailIndex'],
                    roles: ['pm']
                },
                {
                    name: 'Staff Admin',
                    url: '/staff_admin',
                    icon: 'people',
                    active: ['StaffAdminIndex'],
                    roles: ['pm']
                },
                {
                    name: 'Notifikasi',
                    url: '/notifikasi',
                    icon: 'notifications',
                    active: ['NotifikasiIndex', 'NotifikasiForm', 'NotifikasiDetail'],
                    roles: ['dsi', 'company', 'pm']
                },                
            ],
            temp_photo: null,
            logged_user: {
                id: localStorage.getItem('id'),
                name: localStorage.getItem('name'),
                role: localStorage.getItem('role')
            }
        }
    },
    methods: {
        isActive(data){
            let _ = this
            let isThere = data.filter(x => x == _.$route.name)
            if (isThere.length > 0) {
                return 'active';
            }
        },
        isAllowed(data){
            let _ = this
            let isThere = data.filter(x => x == _.logged_user.role)
            if (isThere.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        logout() {
            let _ = this
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			_.$store.dispatch('logout')
			_.$router.push('/login')
        }
    },
    mounted() {
        let _ = this

        _.$root.$on('changeMenuCounter', (data) => {
            _.menus[data.id_menu].counter -= data.counter
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterToZero', (data) => {
            _.menus[data.id_menu].counter = 0
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterTo', (data) => {
            _.menus[data.id_menu].counter = data.counter
            _.$forceUpdate();
        })
    },
}
</script>