<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
        <div class="navbar-wrapper">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <!-- Mobile -->
                        <ul class="nav navbar-nav">
                            <li class="nav-item mobile-menu d-xl-none mr-auto">
                                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" data-toggle="collapse">
                                    <i class="feather icon-align-left ficon"></i>
                                </a>
                            </li>
                        </ul>

                        <!-- Desktop -->
                        <ul class="nav navbar-nav bookmark-icons">
                            <li class="nav-item d-none d-lg-block">
                                <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                    <i class="feather icon-align-left ficon"></i>
                                </a>
                            </li>
                        </ul>
                    </div> 
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-notification nav-item">
                            <a class="nav-link nav-link-label mr-1" href="#" data-toggle="dropdown">
                                <i class="ficon feather icon-bell"></i>
                                <span v-if="notifications.filter(n => n.is_read == false).length > 0"  class="badge badge-pill badge-danger badge-up">{{ notifications.filter(n => n.is_read == false).length }}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                <li class="dropdown-menu-header">
                                    <div class="dropdown-header m-0">
                                        <span class="text-white">Notifikasi</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="count-notif-block">
                                        <p class="font-600 text-primary mb-0">Semua ({{ notifications.length }})</p>
                                    </div>
                                </li>
                                <li class="scrollable-container media-list">
                                    <a 
                                        @click="toDetail(data), data.is_read = true" 
                                        :class="{'readed': data.is_read }" 
                                        v-for="(data, index) in notifications" :key="index">
                                        <div class="media">
                                            <div class="media-body">
                                                <div class="row justify-content-between">
                                                    <div class="col-7">
                                                        <h6 class="font-600">{{ data.title }}</h6>
                                                    </div>
                                                    <div class="col-5 text-right">
                                                        <small>
                                                            <time class="media-meta">{{ data.created_at | moment('LL') }}</time>
                                                        </small>
                                                    </div>
                                                </div>
                                                <p class="notification-text">
                                                    {{ data.description.length > 100 ? data.description.substring(0, 100) + '...' : data.description}}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>                   
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'Navbar',
    data() {
        return {
            temp_photo: null,
            logged_user: {
                name: localStorage.getItem('name'),
                email: localStorage.getItem('email')
            },
            notifications: []
        }
    },
    mounted() {
        let _ = this

        _.getNotification();

        if (localStorage.getItem('photo')){
            if (localStorage.getItem('photo') != null || localStorage.getItem('photo') != 'null' || localStorage.getItem('photo') != 'undefined') {
                _.temp_photo = localStorage.getItem('photo')
            }
        }

        _.$root.$on('changeProfile', (data) => {
            if (data.photo != null){
                localStorage.setItem('photo', data.photo.url)
                _.temp_photo = data.photo.url
            }
            
            localStorage.setItem('name', data.name)
            _.logged_user.name = data.name
        })
    },
	methods: {
		logout() {
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			this.$store.dispatch('logout')
			this.$router.push('/login')
        },
        getNotification() {
            let _ = this
            _.axios.get('/notification/my')
                .then(resp => {
                    _.notifications = resp.data.data || []
                })
        },
        toDetail( data ){
            // window.location = '/notifikasi/' + data.id
            this.$router.push('/notifikasi/' + data.id)
        }
	}
}
</script>